import {
  DxPivotGridDataType,
  GlideDataType,
  PivotArea,
  PivotDataType,
  PivotGridField,
  PivotSummaryType,
} from '../model/dx-pivot-grid.model';
import { GlideDataSource, GlideSchema } from '@virtus/components/DxDataGrid/utils/mapSchemaGlide';
import { formatNumber } from 'src/utils/formatters';
import { updateOnLoadCellValues } from '@virtus/glide/src/utils/cell-calculation-field-rule';

export interface PivotFieldSchema extends GlideSchema {
  pivot_grid_display_area: undefined | string;
  aggregation_calculation: any;
  display_multiplier?: string;
}

export interface PivotGridDataSource extends Omit<GlideDataSource, 'schema'> {
  schema: PivotFieldSchema[];
}

interface mapSchemaTOFieldsProps {
  data: PivotGridDataSource;
  isTreeview?: boolean;
  defaultView?: any;
}

const isDataFieldOnly = (item: PivotFieldSchema) => {
  if (!item.pivot_grid_display_area) {
    return;
  }
  return item.pivot_grid_display_area === 'RC' ? false : undefined;
};

const getAreaForField = (schema: PivotFieldSchema, defaultView: any[]) => {
  const field: any = defaultView.find(item => item.display_name === schema.display_name);
  return field
    ? {
        area: field.area,
        areaIndex: field.areaIndex,
        ...((field.area === PivotArea.R || field.area === PivotArea.C) && { expanded: true }),
      }
    : {};
};

const getSummaryType = (item: any) => {
  if (item.field_name === 'notional_position_percentage') {
    return PivotSummaryType.Sum;
  }

  return item.aggregation_calculation && item.aggregation_calculation !== 'custom'
    ? PivotSummaryType[item.aggregation_calculation as keyof typeof PivotSummaryType]
    : PivotSummaryType.Count;
};

export const isDataCell = (cell: any) => {
  return cell.area === PivotArea.D && cell.rowType === PivotDataType.D && cell.columnType === PivotDataType.D;
};

export const isTotalCell = (cell: any) => {
  return (
    cell.type === PivotDataType.T ||
    cell.type === PivotDataType.GT ||
    cell.rowType === PivotDataType.T ||
    cell.rowType === PivotDataType.GT ||
    cell.columnType === PivotDataType.T ||
    cell.columnType === PivotDataType.GT
  );
};

// TODO: Implement correct schema mapper as we will need to map Glide-object type to correct data type supported by Pivot grid.
export const getPivotFieldsFromSchema = ({
  data,
  defaultView,
  isTreeview = false,
}: mapSchemaTOFieldsProps): PivotGridField[] => {
  return data?.schema.map(
    (field: PivotFieldSchema): PivotGridField => {
      const commonFieldConfig = {
        dataField: field.display_name || field.dataField,
        caption: field.display_name || field.caption,
        isMeasure: isDataFieldOnly(field),
        displayFolder: (isTreeview && field.category) || undefined,
        summaryType: getSummaryType(field),
        ...getAreaForField(field, defaultView),
      };
      switch (field.data_type as GlideDataType) {
        case GlideDataType.Decimal: {
          const checkValue = (value: any) => (value == null ? value : value.toString());
          const retValue = {
            ...commonFieldConfig,
            dataType: DxPivotGridDataType.number,
            format: (value: any) => {
              if (field.format && value) {
                if (field.format.toLowerCase().includes('n') && field.display_multiplier) {
                  return formatNumber({
                    value: value * Number(field.display_multiplier),
                    formatString: field.format.toLowerCase(),
                  });
                } else {
                  return formatNumber({
                    value,
                    formatString: field.format.toLowerCase(),
                    isCellEditable: true,
                  });
                }
              } else {
                return checkValue(value);
              }
            },
            calculateSummaryValue: (e: any) => {
              /**
               * 💡 Pivot grid UX improvement
               * This has been added to hide the row sub totals when the ancestor (parent field group) is having only child
               * to have better visibility of data by hiding duplicate cells.
               */
              if (e.children('row').length === 1) return null;

              if (field?.calculation_script_js) {
                const value = updateOnLoadCellValues(e, field?.calculation_script_js, data?.schema, 'Pivot');
                return value ?? '';
              }
              if (e.value() == 0 || e.value()) {
                return e.value();
              }
            },
          };

          return retValue;
        }
        case GlideDataType.DateTime:
        case GlideDataType.Date: {
          return {
            ...commonFieldConfig,
            dataType: DxPivotGridDataType.date,
            format: (value: any) => {
              return new Intl.DateTimeFormat(navigator.language).format(value);
            },
          };
        }
        case GlideDataType.String:
        case GlideDataType.Object:
        case GlideDataType.LargeString: {
          const retValue = {
            ...commonFieldConfig,
            dataType: DxPivotGridDataType.string,
            summaryType: PivotSummaryType.Custom,
            calculateCustomSummary: (options: any) => {
              switch (options.summaryProcess) {
                case 'start':
                  options.TotalData = {};
                  break;
                case 'calculate':
                  if (!options.TotalData[options.value || 'no_value']) {
                    options.TotalData[options.value || 'no_value'] = options.value;
                  }
                  break;
                case 'finalize': {
                  // eslint-disable-next-line no-case-declarations
                  const arr = Object.values(options.TotalData);
                  const arrLength1 = (arr: any) => (arr.length === 1 ? arr[0] : '#mutlipleValues');
                  options.totalValue = arr.length === 0 ? '' : arrLength1(arr);
                  break;
                }
              }
            },
            selector: (data: any) => {
              return data[field.display_name]?.display_name || data[field.display_name];
            },
          };
          return retValue;
        }
        default:
          return {
            ...commonFieldConfig,
            dataType: DxPivotGridDataType.string,
          };
      }
    },
  );
};
